<template>
  <v-app class="app-bg">
    <v-main>
      <v-layout align-center justify-center v-if="form && !registerScreenVisible">
        <SubviewCard noActions>
          <v-layout column>
            <LabelTextField :value.sync="userId" :disabled="loading" label="UserId" placeholder="UserId...">
            </LabelTextField>
            <LabelTextField :value.sync="businessId" :disabled="loading" label="BusinessId" placeholder="BusinessId...">
            </LabelTextField>
            <LabelTextField :value.sync="email" :disabled="loading" label="Email" placeholder="Token...">
            </LabelTextField>
            <LabelTextField :value.sync="password" :disabled="loading" label="Password" placeholder="Token...">
            </LabelTextField>
            <DefaultButton class="ma-1" primary :loading="loading" @click="handleFakeLogin">Zaloguj</DefaultButton>
          </v-layout>
        </SubviewCard>
      </v-layout>
      <EnableMedicalAddonView v-if="registerScreenVisible" @register="registerAction"></EnableMedicalAddonView>
      <DisabledMedicalAddon :value.sync="showNoAccess"></DisabledMedicalAddon>
      <ConfirmDialog :value.sync="showIncognito" positiveButtonText="Ok" negativeButtonText="" title="Tryb incognito"
        text="Tryb incognito nie jest wspierany w aplikacji." :positiveAction="handleOk"></ConfirmDialog>
    </v-main>
  </v-app>
</template>

<script>
import ApiService from "@/services/api.service";
import AuthService from "@/services/auth.service";
import BooksyService from "@/services/booksy.service";
import QUERY_PARAMS from "@/constants/api";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      form: false,
      userId: "",
      businessId: "",
      token: "",
      email: "",
      password: "",
      showNoAccess: false,
      showIncognito: false,
      registerScreenVisible: false,
      registerAction: () => { }
    };
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    LabelTextField: () => import("@/components/LabelTextField"),
    SubviewCard: () => import("@/components/cards/SubviewCard"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    EnableMedicalAddonView: () => import("../EnableMedicalAddonView.vue"),
    DisabledMedicalAddon: () => import("@/components/popups/DisabledMedicalAddon")
  },
  methods: {
    ...mapActions({
      updateSyncP1: "ui/updateSyncP1",
      updateVisibleCardTypes: "ui/updateVisibleCardTypes",
      updateEnabledMedicalAddon: "ui/updateEnabledMedicalAddon",
      updateLoginData: "auth/updateLoginData",
    }),
    handleFakeLogin() {
      this.$router.replace({
        name: "auth",
        query: {
          state: this.$route.query.state || "settings",
          user_id: this.userId,
          business_id: this.businessId,
        },
      }).catch((error) => console.log("ERROR", error));
      const fakeQueryParams =
        new Map(
          Object.entries({
            user_id: this.userId,
            business_id: this.businessId,
            token: this.token,
          })
        )
      this.tryLogin(fakeQueryParams);
    },
    handleOk() {
      BooksyService.back();
    },
    tryLogin(queryParams) {
      this.check(queryParams).then(
        register => {
          if (register) {
            this.registerScreenVisible = true;
            this.registerAction = (marketingAgreement) => {
              queryParams.set(QUERY_PARAMS.MARKETING_AGREEMENT, marketingAgreement)
              this.register(queryParams);
            }
          } else {
            this.preAuth(queryParams);
          }
        }
      ).catch(error => {
        console.log("ERROR!", error);
      })
    },
    register(queryParams) {
      this.loading = true;
      return AuthService.register(
        queryParams.get(QUERY_PARAMS.USER_ID),
        queryParams.get(QUERY_PARAMS.BUSINESS_ID),
        queryParams.get(QUERY_PARAMS.MARKETING_AGREEMENT)
      ).then(
        () => {
          this.loading = false;
          this.preAuth(queryParams);
        }
      ).catch(
        (error) => {
          this.loading = false;
          this.handleDisabledMedicalAddon(error);
          console.log("ERROR", error);
        }
      )
    },
    check(queryParams) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        return AuthService.check(
          queryParams.get(QUERY_PARAMS.USER_ID),
          queryParams.get(QUERY_PARAMS.BUSINESS_ID)
        ).then(reply => {
          this.loading = false;
          resolve(reply.register);
        }).catch(() => {
          this.loading = false;
          reject();
        })
      });
    },
    preAuth(queryParams) {
      this.loading = true;
      AuthService.preAuth(
        queryParams.get(QUERY_PARAMS.USER_ID),
        queryParams.get(QUERY_PARAMS.BUSINESS_ID),
        this.$route.query.redirect
      )
        .then((reply) => {
          if (queryParams.has("token")) {
            this.auth(
              new Map(
                Object.entries({
                  user_id: this.userId,
                  business_id: this.businessId,
                  code: reply.code,
                  email: this.email,
                  password: this.password,
                })
              )
            );
          } else {
            window.location.replace(reply.url);
          }
        })
        .catch((error) => {
          this.handleDisabledMedicalAddon(error);
          console.log("ERROR", error);
        });
    },
    auth(queryParams) {
      this.loading = true;
      AuthService.auth(
        queryParams.get(QUERY_PARAMS.USER_ID),
        queryParams.get(QUERY_PARAMS.BUSINESS_ID),
        queryParams.get(QUERY_PARAMS.CODE),
        ApiService.urlFromSupportedQueryParams(this.$route, [
          QUERY_PARAMS.CODE,
        ]),
        queryParams.get("email"),
        queryParams.get("password")
      )
        .then((reply) => {
          AuthService.setAuthParams(this.$route);

          this.updateLoginData({
            token: reply.key,
            first_name: reply.first_name,
            last_name: reply.last_name,
            access_level: reply.access_level,
            subscription_active: reply.subscription_active,
            is_superuser: reply.is_superuser
          }).then(() =>
            this.updateSyncP1(reply.sync_p1).then(() =>
              this.updateVisibleCardTypes(reply.visible_card_types).then(() => {
                // Refresh addon state on Booksy side:
                BooksyService.medicalAddonEnabled();
                // Enable it on Booksy Med side:
                this.updateEnabledMedicalAddon(reply.medical_addon_enabled);

                this.$nextTick(() => {
                  if (this.$route.query.redirect) {
                    this.$router.replace(this.$route.query.redirect);
                  } else {
                    const redirect = {
                      name: "/",
                      query: this.$route.query,
                    };
                    this.$router.replace(redirect).catch((error) => console.log("ERROR", error));
                  }
                });
              })
            )
          );
        })
        .catch((error) => {
          this.loading = false;
          this.handleDisabledMedicalAddon(error);
          console.log("ERROR", error.response);
        });
    },
    handleDisabledMedicalAddon(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error === "Disabled medical addon" &&
        error.response.status === 403
      ) {
        this.showNoAccess = true;
      }
    }
  },
  mounted() {
    if (this.showNoAccess) {
      return;
    }

    const supportedQueryParams = ApiService.supportedQueryParamsFromUrl(
      this.$route
    );

    if (this.$route.query.form) {
      this.form = true;
    } else if (supportedQueryParams.has(QUERY_PARAMS.CODE)) {
      this.auth(supportedQueryParams);
    } else {
      this.tryLogin(supportedQueryParams);
    }
  }
};
</script>